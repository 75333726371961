import React, { useRef } from "react"
import emailjs from "emailjs-com"
import * as css from "./DistributorForm.module.css"

const DistributorForm = () => {
  const form = useRef()

  const sendEmail = e => {
    e.preventDefault()
    const btn = document.getElementById("button")
    const i1 = document.getElementById("first")
    const i2 = document.getElementById("last")
    const i3 = document.getElementById("email")
    const i4 = document.getElementById("facilityName")
    const i5 = document.getElementById("facilityType")
    const i6 = document.getElementById("phone")
    const i7 = document.getElementById("state")

    btn.value = "Submitting..."

    emailjs
      .sendForm(
        "service_9c326dq",
        "template_pxo5hb8",
        form.current,
        "user_KKdMFvrpfNAAfKAMLtEc1"
      )
      .then(
        result => {
          i1.value = ""
          i2.value = ""
          i3.value = ""
          i4.value = ""
          i5.value = ""
          i6.value = ""
          i7.value = ""
          btn.value = "Submitted!"
          btn.className = css.form__button_sent
          btn.disabled = true
        },
        error => {
          btn.value = "Submit"
          alert(
            "There was an error sending your message. Please try again later."
          )
        }
      )
  }

  return (
    <form className={css.form_main} ref={form} onSubmit={sendEmail}>
      <Field name="first" text="First name" />
      <Field name="last" text="Last name" />
      <Field name="email" text="Email" />
      <Field name="facilityName" text="Company name" />
      <Field name="facilityType" text="Company type" />
      <Field name="phone" text="Phone" />
      <Field name="state" text="State" />

      <input
        className={css.form__button}
        type="submit"
        value="Submit"
        id="button"
      />
    </form>
  )
}

export default DistributorForm

const states = [
  "AK",
  "AL",
  "AR",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
]

function Field({ name, text }) {
  if (name === "state") {
    return (
      <>
        <label className={css.form__label} for={name}>
          {text}
          <span className={css.required}>*</span>
        </label>
        <select
          required
          className={css.form__input}
          name={name}
          id={name}
          defaultValue="CA"
        >
          {states.map(state => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </select>
      </>
    )
  }

  const type = () => {
    if (name === "Email") return "email"
    if (name === "Phone") return "tel"
    return "text"
  }
  return (
    <>
      <label className={css.form__label} htmlFor={name}>
        {text}
        <span className={css.required}>*</span>
      </label>
      <input
        required
        className={css.form__input}
        type={type()}
        name={name}
        id={name}
      />
    </>
  )
}
