import * as React from "react"
import DistributorForm from "../components/DistributorForm"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Distributors = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="Become a Distributor" />
      <div className="section-wrapper page-main">
        <h1 className="page-main-title">Become a distributor</h1>
        <DistributorForm />
      </div>
    </Layout>
  )
}

export default Distributors
